<template>
  <div class="document-request-from-show-step3">
    <p class="step3"></p>
    <v-row class="ma-0">
      <v-col class="d-flex justify-space-between align-center mt-5 mb-5">
        <span>
          WEB資料請求をいただきありがとうございます。<br>
          ご登録いただいたメールアドレス宛にデータ資料をお送りいたします。
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'ShowDataStep3',
  data () {
    return {}
  },
  mounted () {
    // see TO2020-954
    // eslint-disable-next-line no-unused-expressions
    window.ytag?.({
      type: 'yjad_retargeting',
      config: {
        yahoo_retargeting_id: 'QRHH1NACMN',
        yahoo_retargeting_label: '',
        yahoo_retargeting_page_type: '',
        yahoo_retargeting_items: [
          { item_id: '', category_id: '', price: '', quantity: '' }
        ]
      }
    })
  },
  methods: {}
}
</script>

<style lang="scss">

</style>
