<template>
  <div class="comp-stepList">
    <ol class="list row">
      <li v-for="(item, index) in dataItems" :key="index" :class="[{'is-current': item.active}, 'col']">
        <div class="outer">
          <h2 :class="['inner']">
            <span class="title-step">{{ item.name }}</span>
            <!--  <span class="description">description</span>-->
          </h2>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>

export default {
  name: 'BreadcrumbStep',
  data () {
    return {
    }
  },
  props: {
    dataItems: {
      type: Array,
      required: true
    }
  },
  model: {
    event: 'eventChangeStep'
  },
  components: {
  },
  mounted () {
  },
  methods: {
    onClickStep (data) {
      this.$emit('eventChangeStep', data)
    }
  }
}
</script>

<style lang="scss">

</style>
