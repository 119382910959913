import { mapState } from 'vuex'
import C from '@/utils/constant'
const NOT_SPECIFIED = '指定なし'

export default {
  data () {
    return {
      typeApply: ''
    }
  },
  mounted () {
  },
  methods: {
    forVmOrForVs (value) {
      // どちらも希望  all
      // If バケーションマスター is selected, forVm = true, forVs = false. If バケーションスタイル is selected, forVm = false, forVs = true
      const self = this
      let forVm = false
      let forVs = false
      switch (value) {
        case self.constant.WEB_REQUEST_FOR_TYPE_MEMBER.primeMember:
          forVm = true
          forVs = false
          break
        case self.constant.WEB_REQUEST_FOR_TYPE_MEMBER.vacationsClubMember:
          forVm = false
          forVs = true
          break
        case self.constant.WEB_REQUEST_FOR_TYPE_MEMBER.bothHope:
          forVm = true
          forVs = true
          break
        default:
          window.location.reload()
      }
      return {
        forVm,
        forVs
      }
    },
    birthdayFull (birthdayYear, birthdayMonth, birthdayDay) {
      const m = (+this.mainFormDocRequest?.birthdayMonth < 10 ? '0' : '') + +this.mainFormDocRequest?.birthdayMonth
      const d = (+this.mainFormDocRequest?.birthdayDay < 10 ? '0' : '') + +this.mainFormDocRequest?.birthdayDay
      return `${this.mainFormDocRequest?.birthdayYear}-${m}-${d}`
    },
    baseCreateWebRequestForMainClient () {
      const self = this
      return {
        lastName: self.mainFormDocRequest.userLastName,
        firstName: self.mainFormDocRequest.userFirstName,
        lastKana: self.mainFormDocRequest.kanaLastName,
        firstKana: self.mainFormDocRequest.kanaFirstName,
        birthday: self.birthdayFull(this.mainFormDocRequest?.birthdayYear, this.mainFormDocRequest?.birthdayMonth, this.mainFormDocRequest?.birthdayDay),
        ...self.mainFormDocRequest?.homePhoneNumber && self.mainFormDocRequest?.homePhoneNumber.length && { tel: self.mainFormDocRequest.homePhoneNumber },
        ...self.mainFormDocRequest?.mobileNumber && self.mainFormDocRequest?.mobileNumber.length && { telMobile: self.mainFormDocRequest.mobileNumber },
        email: self.mainFormDocRequest.email,
        postalCode: self.mainFormDocRequest.postalCode,
        prefecture: self.mainFormDocRequest.prefecture.name, // value
        locality: self.mainFormDocRequest.cityOrCounty,
        address1: self.mainFormDocRequest.streetBunch,
        address2: self.mainFormDocRequest.addressOther
      }
    },
    convertDataDateTimeOther () {
      return {
        ...this.dataFormDataTimeOtherOfVisitOnline?.firstDate && !!this.dataFormDataTimeOtherOfVisitOnline?.firstDate.length && { firstDate: this.dataFormDataTimeOtherOfVisitOnline?.firstDate },
        ...this.dataFormDataTimeOtherOfVisitOnline?.firstTime && !!this.dataFormDataTimeOtherOfVisitOnline?.firstTime.length && { firstTime: this.dataFormDataTimeOtherOfVisitOnline?.firstTime },
        ...this.dataFormDataTimeOtherOfVisitOnline?.secondDate && !!this.dataFormDataTimeOtherOfVisitOnline?.secondDate.length && { secondDate: this.dataFormDataTimeOtherOfVisitOnline?.secondDate },
        ...this.dataFormDataTimeOtherOfVisitOnline?.secondTime && !!this.dataFormDataTimeOtherOfVisitOnline?.secondTime.length && { secondTime: this.dataFormDataTimeOtherOfVisitOnline?.secondTime },
        ...this.dataFormDataTimeOtherOfVisitOnline?.otherDateTime && !!this.dataFormDataTimeOtherOfVisitOnline?.otherDateTime.length && { otherDateTime: this.dataFormDataTimeOtherOfVisitOnline?.otherDateTime },
        ...this.dataFormDataTimeOtherOfVisitOnline?.inquiry && !!this.dataFormDataTimeOtherOfVisitOnline?.inquiry.length && { inquiry: this.dataFormDataTimeOtherOfVisitOnline?.inquiry }
      }
    },
    convertDataTrialStay () {
      return {
        ...this.trialStayOptional?.firstCheckInDate && !!this.trialStayOptional?.firstCheckInDate.length && { firstCheckInDate: this.trialStayOptional?.firstCheckInDate },
        ...this.trialStayOptional?.firstStayDays && { firstStayDays: this.trialStayOptional?.firstStayDays },
        ...this.trialStayOptional?.firstFacility?.id && { firstFacilityId: this.trialStayOptional?.firstFacility.id },
        ...this.trialStayOptional?.secondCheckInDate && !!this.trialStayOptional?.secondCheckInDate.length && { secondCheckInDate: this.trialStayOptional?.secondCheckInDate },
        ...this.trialStayOptional?.secondStayDays && { secondStayDays: this.trialStayOptional?.secondStayDays },
        ...this.trialStayOptional?.secondFacility?.id && { secondFacilityId: this.trialStayOptional?.secondFacility.id }
      }
    },
    baseCreateWebRequestForCorporateCustomer () {
      const self = this
      let workStyleReform = ''
      if (self.mainCorporateCustomer?.workStyleReform && self.mainCorporateCustomer.workStyleReform?.value === self.constant.WORK_STYLE_REFORM_TRUE) {
        workStyleReform = 'T'
      }
      if (self.mainCorporateCustomer?.workStyleReform && self.mainCorporateCustomer.workStyleReform?.value === self.constant.WORK_STYLE_REFORM_FALSE) {
        workStyleReform = 'F'
      }
      return {
        companyName: self.mainCorporateCustomer.companyName,
        companyNameKana: self.mainCorporateCustomer.companyNameKana,
        businessTypeId: +self.mainCorporateCustomer.businessType.id, // Id
        ...self.mainCorporateCustomer?.businessTypeOther && self.mainCorporateCustomer?.businessTypeOther.length && { businessTypeOther: self.mainCorporateCustomer.businessTypeOther },
        companyPicName: self.mainCorporateCustomer.companyPicName,
        companyPicKana: self.mainCorporateCustomer.companyPicKana,
        ...self.mainCorporateCustomer?.companyPicDepartment && self.mainCorporateCustomer?.companyPicDepartment.length && { companyPicDepartment: self.mainCorporateCustomer.companyPicDepartment },
        ...self.mainCorporateCustomer?.companyPicTitle && self.mainCorporateCustomer?.companyPicTitle.length && { companyPicTitle: self.mainCorporateCustomer.companyPicTitle },
        companySize: +self.mainCorporateCustomer.companySize,
        postalCode: self.mainCorporateCustomer.postalCode,
        prefecture: self.mainCorporateCustomer.prefecture.name, // value
        locality: self.mainCorporateCustomer.cityOrCounty,
        address1: self.mainCorporateCustomer.streetBunch,
        ...self.mainCorporateCustomer?.addressOther && self.mainCorporateCustomer?.addressOther.length && { address2: self.mainCorporateCustomer.addressOther },
        ...self.mainCorporateCustomer?.homePhoneNumber && self.mainCorporateCustomer?.homePhoneNumber.length && { tel: self.mainCorporateCustomer.homePhoneNumber },
        ...self.mainCorporateCustomer?.mobileNumber && self.mainCorporateCustomer?.mobileNumber.length && { telMobile: self.mainCorporateCustomer.mobileNumber },
        email: self.mainCorporateCustomer.email,
        ...self.mainCorporateCustomer.inquiry && { inquiry: self.mainCorporateCustomer.inquiry },
        ...workStyleReform.length && { workStyleReform: workStyleReform === 'T' }
      }
    },
    facilities () {
      const self = this
      return self.facilityInterestedOption1
    },
    seasons () {
      return this.facilityInterestedOption2
    },
    medias () {
      const self = this
      let results = []
      const hasSNSCheckedIndex = self.findGeneral.findIndex(ele => ele.text.toUpperCase() === 'SNS')
      const trueGeneral = self.findGeneral.filter((_el, ind) => ind !== hasSNSCheckedIndex)
      if (trueGeneral.length) {
        results = results.concat(trueGeneral.map(ele => ({ mediaType: ele.text, mediaOther: null })))
      }

      if (self.findBySNS.length) {
        results = results.concat(self.findBySNS.map(ele => ({ mediaType: ele.text, mediaOther: null })))
      } else if (hasSNSCheckedIndex >= 0) {
        results.push({ mediaType: 'SNS その他', mediaOther: null })
      }

      if (self.findByInputOther.length) {
        results.push({
          mediaType: 'その他',
          mediaOther: self.findByInputOther
        })
      }

      return results
    },
    convertDataBeforeCreateForScreenFirst () {
      const self = this
      const facilities = self.facilities()
      const seasons = this.seasons()
      const medias = self.medias()
      return {
        data: {
          type: this.dataClientDocRequest.type,
          ...self.forVmOrForVs(self.requestForMember?.value),
          ...self.baseCreateWebRequestForMainClient(),
          isCompany: false, // Fix
          ...facilities.length && { facilities: facilities },
          seasons
        },
        ...medias.length && { medias }
      }
    },
    convertDataBeforeCorporateCustomer () {
      const self = this
      const facilities = self.facilities()
      const seasons = this.seasons()
      const medias = self.medias()
      return {
        data: {
          type: this.dataCorporateCustomer.type,
          ...self.baseCreateWebRequestForCorporateCustomer(),
          ...self.forVmOrForVs(self.requestForCorporate?.value),
          isCompany: true, // Fix
          ...facilities.length && { facilities: facilities },
          seasons
        },
        ...medias.length && { medias }
      }
    },
    convertDataBeforeForVisitReservation () {
      const self = this
      return {
        data: {
          type: this.dataVisitReservation.type,
          ...self.convertDataDateTimeOther(),
          ...self.baseCreateWebRequestForMainClient(),
          isCompany: false // Fix
        }
      }
    },
    convertDataBeforeForOnlineConsultation () {
      const self = this
      return {
        data: {
          type: this.dataOnlineConsultation.type,
          ...self.convertDataDateTimeOther(),
          ...self.baseCreateWebRequestForMainClient(),
          isCompany: false // Fix
        }
      }
    },
    convertDataBeforeForTrialStay () {
      const self = this
      return {
        data: {
          type: this.dataTrialStay.type,
          ...self.convertDataTrialStay(),
          ...self.baseCreateWebRequestForMainClient(),
          isCompany: false // Fix
        }
      }
    },
    scrollIntoViewSmooth (element = '') {
      this.$nextTick(() => {
        if (element && document.querySelector(element)) {
          document.querySelector(element).scrollIntoView({
            behavior: 'smooth'
          })
        }
      })
    },
    showMessageDbClick (toast, type, msg) {
      if (type === 'w') {
        toast.warning(msg)
      }
    }
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      dataClientDocRequest: state => state.documentRequest.dataClientDocRequest,
      dataCorporateCustomer: state => state.documentRequest.dataCorporateCustomer,
      dataOnlineConsultation: state => state.documentRequest.dataOnlineConsultation,
      dataVisitReservation: state => state.documentRequest.dataVisitReservation,
      dataTrialStay: state => state.documentRequest.dataTrialStay
    }),
    constant () {
      return Object.assign({}, C)
    },
    mainFormDocRequest () {
      switch (this.typeApply) {
        case 'CLIENT':
          return this.dataClientDocRequest.mainFormDocRequest
        case 'VISIT_RESERVATION':
          return this.dataVisitReservation.mainFormDocRequest
        case 'ONLINE_CONSULTATION':
          return this.dataOnlineConsultation.mainFormDocRequest
        case 'TRIAL_STAY':
          return this.dataTrialStay.mainFormDocRequest
        default:
          return {}
      }
    },
    mainCorporateCustomer () {
      return this.dataCorporateCustomer.mainFormCorporate
    },
    requestForMember () {
      // Object of client
      return this.dataClientDocRequest.requestForMember
    },
    requestForCorporate () {
      // Object of client
      return this.dataCorporateCustomer.requestForCorporate
    },
    facilityInterestedOption1 () {
      if (this.typeApply === 'CLIENT') {
        return this.dataClientDocRequest.facilityInterestedOption1 && Array.isArray(this.dataClientDocRequest.facilityInterestedOption1)
          ? this.dataClientDocRequest.facilityInterestedOption1 : []
      }

      if (this.typeApply === 'CORPORATE') {
        return this.dataCorporateCustomer.facilityInterestedOption1 && Array.isArray(this.dataCorporateCustomer.facilityInterestedOption1)
          ? this.dataCorporateCustomer.facilityInterestedOption1 : []
      }
      return []
    },
    facilityInterestedOption2 () {
      if (this.typeApply === 'CLIENT') {
        return this.dataClientDocRequest.facilityInterestedOption2 && Array.isArray(this.dataClientDocRequest.facilityInterestedOption2)
          ? this.dataClientDocRequest.facilityInterestedOption2 : []
      }
      if (this.typeApply === 'CORPORATE') {
        return this.dataCorporateCustomer.facilityInterestedOption2 && Array.isArray(this.dataCorporateCustomer.facilityInterestedOption2)
          ? this.dataCorporateCustomer.facilityInterestedOption2 : []
      }

      return []
    },
    findAboutVacation () {
      if (this.typeApply === 'CLIENT') {
        return this.dataClientDocRequest.findAboutVacation
      }

      if (this.typeApply === 'CORPORATE') {
        return this.dataCorporateCustomer.findAboutVacation
      }

      return {}
    },
    dataFormDataTimeOtherOfVisitOnline () {
      if (this.typeApply === 'VISIT_RESERVATION') {
        return this.dataVisitReservation.visitReservationOptional
      }
      if (this.typeApply === 'ONLINE_CONSULTATION') {
        return this.dataOnlineConsultation.onlineConsultationOptional
      }

      return {}
    },
    findGeneral () {
      return this.findAboutVacation?.finds ? this.findAboutVacation.finds.filter(ele => !(ele.value === this.constant.OTHER_KEY)) : []
    },
    findBySNS () {
      const find = this.findAboutVacation?.finds ? this.findAboutVacation.finds.filter(ele => ele.value === this.constant.SNS_KEY) : []

      return find.length && this.findAboutVacation?.findBySNS ? this.findAboutVacation?.findBySNS : []
    },
    findByInputOther () {
      const find = this.findAboutVacation?.finds ? this.findAboutVacation.finds.filter(ele => ele.value === this.constant.OTHER_KEY) : []

      return find.length && this.findAboutVacation?.findByInputOther ? this.findAboutVacation?.findByInputOther : ''
    },
    hasInquiry () {
      return this.dataFormDataTimeOtherOfVisitOnline && Object.keys(this.dataFormDataTimeOtherOfVisitOnline).length && this.dataFormDataTimeOtherOfVisitOnline?.inquiry &&
        !!this.dataFormDataTimeOtherOfVisitOnline.inquiry.length
    },
    firstDateTime () {
      return this.checkFirstDataTimeOptional ? `${this.dataFormDataTimeOtherOfVisitOnline.firstDate || NOT_SPECIFIED} ${this.dataFormDataTimeOtherOfVisitOnline.firstTime || NOT_SPECIFIED}` : ''
    },
    secondDateTime () {
      return this.checkSecondDataTimeOptional ? `${this.dataFormDataTimeOtherOfVisitOnline.secondDate || NOT_SPECIFIED} ${this.dataFormDataTimeOtherOfVisitOnline.secondTime || NOT_SPECIFIED}` : ''
    },
    hasDateTimeOther () {
      return this.dataFormDataTimeOtherOfVisitOnline && Object.keys(this.dataFormDataTimeOtherOfVisitOnline).length
    },
    checkFirstDataTimeOptional () {
      return this.hasDateTimeOther && (this.dataFormDataTimeOtherOfVisitOnline?.firstDate || this.dataFormDataTimeOtherOfVisitOnline?.firstTime)
    },
    checkSecondDataTimeOptional () {
      return this.hasDateTimeOther && (this.dataFormDataTimeOtherOfVisitOnline?.secondDate || this.dataFormDataTimeOtherOfVisitOnline?.secondTime)
    },
    checkOtherDataTimeOptional () {
      return this.hasDateTimeOther && this.dataFormDataTimeOtherOfVisitOnline?.otherDateTime
    },
    hasDateTimeOtherAllField () {
      return [
        this.checkFirstDataTimeOptional,
        this.checkSecondDataTimeOptional,
        this.checkOtherDataTimeOptional
      ].some(ele => !!ele)
    },
    trialStayOptional () {
      return this.dataTrialStay.trialStayOptional
    }
  },
  created () {
    // Call number read message
  }
}
