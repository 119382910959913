export function isEmailValid (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function isPostCodeValid (postalCode) {
  const re = /^[0-9]{7}$/
  return re.test(String(postalCode).toLowerCase())
}

export function isHomePhoneNumberValid (mobileNumber) {
  const re = /^[0]\d{1,3}[-]?\d{1,4}[-]?\d{3,4}$/
  return re.test(String(mobileNumber).toLowerCase())
}

export function isMobileNumberValid (mobileNumber) {
  const re = /^[0]\d{2,3}[-]?\d{1,4}[-]?\d{3,4}$/
  return re.test(String(mobileNumber).toLowerCase())
}

export function dateCheckBirthdayValid (year, month, date) {
  const d = new Date()
  const m = `${((1 + d.getMonth()) < 10 ? '0' : '')}${(1 + d.getMonth())}`
  const dd = `${((1 + d.getDate()) < 10 ? '0' : '')}${(1 + d.getDate())}`
  const dateCheck = /^(0?[1-9]|[12][0-9]|3[01])$/
  const monthCheck = /^(0[1-9]|1[0-2])$/
  const yearCheck = /^\d{4}$/

  month = +month < 10 ? `0${+month}` : month
  date = +date < 10 ? `0${+date}` : date

  if (isNaN(+year) || isNaN(+month) || isNaN(+date)) {
    return false
  }

  if (`${d.getFullYear()}${m}${dd}` <= `${year}${month}${date}`) {
    return false
  }

  if (`${month}`.match(monthCheck) && `${date}`.match(dateCheck) && `${year}`.match(yearCheck)) {
    const listOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (+month === 1 || +month > 2) {
      if (+date > listOfDays[+month - 1]) {
        return false
      }
    }

    if (+month === 2) {
      let leapYear = false
      if ((!(+year % 4) && +year % 100) || !(+year % 400)) {
        leapYear = true
      }
      if (leapYear === false && +date >= 29) {
        return false
      }
      if (leapYear === true && +date > 29) {
        return false
      }
    }
  }

  return true
}

export function isKanaValid (kanaString) {
  // eslint-disable-next-line no-irregular-whitespace
  const re = new RegExp(/^([゠ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺ・ーヽヾヿ 　]+)$/)
  return re.test(String(kanaString).toLowerCase())
}
