<template>
  <v-card class="responsive-base-card-pa">
    <v-card-title class="bg-title-main justify-space-between pa-0 pl-3">
      <span class="d-custom-title-form">同意事項</span>
      <v-chip class="ma-2 d-label-required-bg" label text-color="white">
        {{ $t('common.labelRequired') }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <div class="pa-12-pb-2">
        <p class="pb-2 font-policy-agree">{{ description }}</p>
        <v-checkbox class="pb-2 d-flex align-start"
                    v-model="vModelPolicyAgree"
                    :rules="rules"
                    :label="label"
                    @change="onClickPolicyAgree"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'PolicyAgree',
  data () {
    return {
      vModelPolicyAgree: false,
      description: this.$t('common.policyDescription'),
      label: this.$t('common.policyLabel')
    }
  },
  props: {
    typeValid: {
      type: String,
      required: false
    },
    policyDescription: {
      type: String,
      required: false
    },
    policyLabel: {
      type: String,
      required: false
    },
    dataModel: {
      type: Boolean,
      required: false
    }
  },
  model: {
    event: 'eventPolicyAgree'
  },
  mounted () {
    if (this.policyDescription && this.policyDescription.length) {
      this.description = this.policyDescription
    }
    if (this.policyLabel && this.policyLabel.length) {
      this.label = this.policyLabel
    }
    if (this.dataModel) {
      this.vModelPolicyAgree = this.dataModel
    }
    this.onClickPolicyAgree()
  },
  methods: {
    onClickPolicyAgree () {
      this.$emit('eventPolicyAgree', this.vModelPolicyAgree)
    }
  },
  computed: {
    rules () {
      return [
        this.vModelPolicyAgree || this.$t('rules.policyAgree')
      ]
    }
  }
}
</script>

<style lang="scss">
  .responsive-base-card-pa {
    margin: 48px 0;
    .font-policy-agree {
      font-size: 16px;
    }
  }
</style>
