<template>
  <div class="responsive-base-card">
    <div class="d-flex justify-end">
      <slot name="additionalInfo" />
    </div>
    <h1 class="my-4 page-title-main">
      <slot name="title" />
      <slot name="actions" />
    </h1>
    <template>
      <slot name="default" />
    </template>
  </div>
</template>

<script>

export default {
  name: 'BaseContentIntro',
  data () {
    return {
      title: 'BaseContentIntro',
      tab: null
    }
  },
  components: {
  }
}
</script>

<style lang="scss">
.responsive-base-card {
  width: 100%;
  padding: 20px 0;
 .color-title {
   font-weight: normal;
 }
}
</style>
