<template>
  <v-card :class="'icon-footer'">
    <v-card-title class="ma-0 pa-0">
      <div v-if="business">
        <div>コールセンター業務連絡電話番号（非公開）</div>
        <h4>03ｰ3406ｰ0176</h4>
        <div>営業時間 9:30~19:00（休業：祝日・年末年始・臨時休あり）</div>
      </div>
      <template v-else>
        <v-img class="d-none d-sm-block"
          src="@/assets/images/footer.svg"
          contain
        />
        <v-img class="d-sm-none"
          src="@/assets/images/footer-mobile.svg"
          contain
        />
      </template>
    </v-card-title>
  </v-card>
</template>

<script>

export default {
  data: function () {
    return {
    }
  },
  props: {
    funcGotoBack: {
      type: Function,
      defaultValue: null
    },
    typeComponent: String,
    titleComponent: {
      type: String,
      defaultValue: ''
    },
    placeholder: {
      type: String,
      defaultValue: ''
    },
    business: Boolean
  },
  components: {
  }
}
</script>
<style lang="scss">
.icon-footer {
  background-color: #f5f4e2 !important;
  border: 1px solid #f5f4e2 !important;
  box-shadow: unset !important;
  .row {
    padding: 30px 24px;
    .d-matching {
      background: white;
      border-radius: 25px;
      color: #7e6768;
      padding: 3px 0;
    }
    .cus-log-contract-2 {
      width: 300px;
    }
    .time {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
